import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { ContentContainer } from '../../styles/styled';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import Image1 from '../../assets/images/services-carousel-1.png';
import Image2 from '../../assets/images/services-carousel-2.png';
import Image3 from '../../assets/images/services-carousel-3.png';

const ItemContainer = styled(Box)`
  img {
    object-fit: cover;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
  }
`;

const Carousel = () => {
  return (
    <Box bgcolor="#000000" color="#ffffff">
      <ContentContainer>
        <Grid container spacing={4}>
          {/*<Grid item xs={12} md={4}>
            <Box display="flex">
              <NumberText variant="h3">02</NumberText>
              <Box>
                <Typography style={{ marginBottom: '20px' }} variant="h3">
                  <FormattedHTMLMessage id="servicesCarouselTitle" />
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '40px' }}>
                  <FormattedHTMLMessage id="servicesCarouselText" />
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={{ color: '#A259FF', marginRight: '12px' }}
                  >
                    <FormattedHTMLMessage id="servicesCarouselMore" />
                  </Typography>
                  <ExternalLinkIcon fill="#A259FF" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <CarouselReachRight slides={CarouselData} SlideComponent={Slide} />
          </Grid>*/}
          <Grid item xs={12} md={4}>
            <ItemContainer>
              <img src={Image1} alt="xray car" />
              <Typography
                variant="h6"
                style={{ marginBottom: '10px', lineHeight: '19.65px' }}
              >
                <FormattedHTMLMessage id="servicesCarouselItem1title" />
              </Typography>
              <Typography
                style={{
                  fontSize: '1rem',
                  color: '#6D6D6D',
                  lineHeight: '17.47px',
                }}
              >
                <FormattedHTMLMessage id="servicesCarouselItem1text" />
              </Typography>
            </ItemContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <ItemContainer>
              <img src={Image2} alt="discussion over the whiteboard" />
              <Typography
                variant="h6"
                style={{ marginBottom: '10px', lineHeight: '19.65px' }}
              >
                <FormattedHTMLMessage id="servicesCarouselItem2title" />
              </Typography>
              <Typography
                style={{
                  fontSize: '1rem',
                  color: '#6D6D6D',
                  lineHeight: '17.47px',
                }}
              >
                <FormattedHTMLMessage id="servicesCarouselItem2text" />
              </Typography>
            </ItemContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <ItemContainer>
              <img src={Image3} alt="car with open doors" />
              <Typography
                variant="h6"
                style={{ marginBottom: '10px', lineHeight: '19.65px' }}
              >
                <FormattedHTMLMessage id="servicesCarouselItem3title" />
              </Typography>
              <Typography
                style={{
                  fontSize: '1rem',
                  color: '#6D6D6D',
                  lineHeight: '17.47px',
                }}
              >
                <FormattedHTMLMessage id="servicesCarouselItem3text" />
              </Typography>
            </ItemContainer>
          </Grid>
        </Grid>
      </ContentContainer>
    </Box>
  );
};

export default Carousel;
