import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';

import BannerImage from '../../assets/images/services-banner.png';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const Content = styled(ContentContainer)`
  padding: 60px 1.25rem;
  ${theme.breakpoints.up('md')} {
    padding: 180px 0;
  }
`;

const DimmedText = styled(Typography)`
  color: rgba(147, 162, 174, 0.2);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const FeatureText = styled(Typography)`
  color: #77e7ff;
  border: 1px solid #ffffff;
  background-color: rgba(119, 231, 255, 0.2);
  backdrop-filter: blur(7px);
  border-radius: 24px;
  padding: 12px 16px;
  letter-spacing: 1px;
  margin-right: 24px;
  margin-bottom: 6px;
  line-height: 21px;
`;

const Banner = () => {
  return (
    <ResponsiveImage
      style={{
        backgroundImage: `url(${BannerImage})`,
        height: 'auto',
        backgroundPosition: 'right',
        backgroundColor: '#000000',
        backgroundSize: 'contain',
      }}
    >
      <Content>
        <Box maxWidth="525px" position="relative" color="#ffffff">
          <Typography
            variant="h1"
            gutterBottom
            style={{ zIndex: '1', position: 'relative' }}
          >
            <FormattedHTMLMessage id="servicesBannerTitle" />
          </Typography>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0">
              <DimmedText>
                <FormattedHTMLMessage id="servicesBannerDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <Box maxWidth="700px" mb="3.125rem" color="#ffffff">
          <Typography variant="body1" style={{ letterSpacing: '1px' }}>
            <FormattedHTMLMessage id="servicesBannerText" />
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <FeatureText variant="body1">
            <FormattedHTMLMessage id="servicesBanner1" />
          </FeatureText>
          <FeatureText variant="body1">
            <FormattedHTMLMessage id="servicesBanner2" />
          </FeatureText>
          <FeatureText variant="body1">
            <FormattedHTMLMessage id="servicesBanner3" />
          </FeatureText>
          <FeatureText variant="body1">
            <FormattedHTMLMessage id="servicesBanner4" />
          </FeatureText>
        </Box>
      </Content>
    </ResponsiveImage>
  );
};

export default Banner;
