import React from 'react';

import SEO from '../../components/SEO';
import Layout from '../../layout/AppLayout';
import { Banner, Carousel, Features } from '../../screens/Services';
import Contact from '../../components/Contact';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Services" />
      <Banner />
      <Carousel />
      <Features />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
