import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

import BackgroundImage from '../../assets/images/services-features-image.png';
import ExternalLinkIcon from '../../assets/icons/external-link.inline.svg';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const NumberText = styled(Typography)`
  color: #b0b0b0;
  margin-right: 10px;

  ${theme.breakpoints.up('md')} {
    margin-right: 10px;
  }

  ${theme.breakpoints.up('lg')} {
    margin-right: 60px;
  }
`;

const Content = styled(ContentContainer)`
  ${theme.breakpoints.up('md')} {
    padding: 141px 0 141px;
  }
`;

const Features = () => {
  return (
    <Box>
      <ResponsiveImage
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundColor: '#3F3F3F',
          color: '#ffffff',
        }}
      >
        <Content>
          <Grid container justify="space-between" spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography style={{ marginBottom: '20px' }} variant="h3">
                <FormattedHTMLMessage id="servicesCarouselTitle" />
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '40px' }}>
                <FormattedHTMLMessage id="servicesCarouselText" />
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  style={{ color: '#04D2FF', marginRight: '12px' }}
                >
                  <FormattedHTMLMessage id="servicesCarouselMore" />
                </Typography>
                <ExternalLinkIcon fill="#04D2FF" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography style={{ marginBottom: '20px' }} variant="h3">
                <FormattedHTMLMessage id="servicesFeatures1Title" />
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '40px' }}>
                <FormattedHTMLMessage id="servicesFeatures1Text" />
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  style={{ color: '#04D2FF', marginRight: '12px' }}
                >
                  <FormattedHTMLMessage id="servicesFeaturesMore" />
                </Typography>
                <ExternalLinkIcon fill="#04D2FF" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography style={{ marginBottom: '20px' }} variant="h3">
                <FormattedHTMLMessage id="servicesFeatures2Title" />
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '40px' }}>
                <FormattedHTMLMessage id="servicesFeatures2Text" />
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  style={{ color: '#04D2FF', marginRight: '12px' }}
                >
                  <FormattedHTMLMessage id="servicesFeaturesMore" />
                </Typography>
                <ExternalLinkIcon fill="#04D2FF" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography style={{ marginBottom: '20px' }} variant="h3">
                <FormattedHTMLMessage id="servicesFeatures3Title" />
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '40px' }}>
                <FormattedHTMLMessage id="servicesFeatures3Text" />
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  style={{ color: '#04D2FF', marginRight: '12px' }}
                >
                  <FormattedHTMLMessage id="servicesFeaturesMore" />
                </Typography>
                <ExternalLinkIcon fill="#04D2FF" />
              </Box>
            </Grid>
          </Grid>
        </Content>
      </ResponsiveImage>
    </Box>
  );
};

export default Features;
